.app {
  font-family: sans-serif;
  text-align: center;
  opacity: 0;
  transition: opacity 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.counter {
  padding: 10px;
  font-size: 30px;
}

.cm-editor {
  min-width: 15em;
  width: auto;
}

.cm-content {
  font-family: monospace;
  font-size: 30px;
  text-transform: uppercase;
}

.correct,
.present,
.absent {
  margin: 1px;
  padding: 5px 10px;
  background-color: transparent;
  transition: background-color 0.1s;
}

.correct {
  background-color: green;
}

.present {
  background-color: rgb(255, 196, 0);
}

.absent {
  background-color: rgb(211, 211, 211);
}

.cm-editor {
  border: 1px solid black;
  padding: 10px;
  border-radius: 4px;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.complete .correct,
.complete .present,
.complete .absent {
  background: linear-gradient(
    60deg,
    #f79533,
    #f37055,
    #ef4e7b,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
  background-size: 500% 500%;
  animation: 3s ease infinite alternate animatedgradient;
}

.info-button {
  position: fixed;
  top: 10px;
  right: 10px;
}

.info {
  position: fixed;
  top: 10px;
  right: 60px;
  bottom: 10px;
  left: 10px;
  background: white;
  box-shadow: 0 1px 2px #000000;
  border-radius: 3px;
}
